import DemoPageTemplate from "components/demo/DemoPageTemplate";
import { blockPopupAtom } from "components/page/atoms/globalAtoms";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import { useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function FreeTrial() {
  const setBlockPopup = useSetAtom(blockPopupAtom);
  useEffect(() => {
    setBlockPopup(true);
    return () => {
      setBlockPopup(false);
    };
  }, []);
  const renderContent = data => {
    const pageData = {
      header: {
        title: "Try Rhombus Risk-Free",
        paragraph:
          "Contact our team for a free trial and experience the benefits of Rhombus in your unique environment.",
        formId: "6f4405c2-70e5-45a8-b139-cad010d9e4e3",
        image: data.headerImage,
        mobileImage: data.mobileHeaderImage,
        theme: "light",
      },
      featured: {
        theme: "dark",
        logos: [
          {
            img: data.logo1,
            width: "130px",
            alt: "Hurley",
          },
          {
            img: data.logo2,
            width: "177px",
            alt: "Aramark",
          },
          {
            img: data.logo3,
            width: "115px",
            alt: "Stryker",
          },
          {
            img: data.logo4,
            width: "186px ",
            alt: "Los Angeles Film School",
          },
          {
            img: data.logo5,
            width: "149px",
            alt: "Goodwill",
          },
          {
            img: data.logo6,
            width: "137px",
            alt: "Clark Construction",
          },
        ],
      },
      learn: {
        image: data.learnImage,
        title: "What to Expect",
        paragraph:
          "Chat with a dedicated Rhombus Expert to address your precise technology requirements and discuss any challenges you wish to overcome. Our expert will curate a tailor-made trial kit specifically designed for testing within your distinct industry and environment.",
        list: {
          title: "Standard kits include",
          items: [
            "Rhombus security cameras and sensors",
            "Full cloud license",
            "On-camera and cloud archiving",
            "Advanced AI Analytics",
            "Free US-based technical support",
            "Free shipping both ways",
          ],
        },
      },
      benefits: {
        title: "The Benefits of Rhombus",
        list: [
          {
            title: "Enjoy an all-in-one solution that grows with your needs.",
            tabTitle: "Infinite Scalability",

            description:
              "With a broad suite of products that is constantly growing, Rhombus provides a true single-pane-of-glass experience. Remotely manage access control, security cameras, alarms, sensors, and integrations from a centralized platform to improve visibility and operations at scale.",
            image: data.benefit1,
          },
          {
            tabTitle: "Seamless Deployment",
            title: "Register plug-and-play devices in as little as 5 minutes.",
            description:
              "Complex NVR/DVRs are a thing of the past. Rhombus’ modern architecture ensures fast and easy deployment from anywhere in the world. With simplified hardware infrastructure, installation is seamless with a single cable connection via PoE, eliminating the need for manual updates. ",
            image: data.benefit2,
          },
          {
            tabTitle: "Proactive Alerts",
            title:
              "Stay in the know with real-time alerts for faces, vehicles, and more.",
            description:
              "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
            image: data.benefit4,
          },
          {
            tabTitle: "Easy Investigations",
            title:
              "Save time with multi-camera investigations and smart search.",
            description:
              "No more manual scrubbing. Camera and sensor data are layered together for fast investigations. Quickly search past footage, search for events, and securely share clips via email, text, or URL all from the Rhombus timeline.",
            image: data.benefit3,
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "handshake",
          title: "Trusted Partner",
          p:
            "Our mission is to build a true partnership and provide simple, smart, and powerful solutions that fit your unique needs.",
        },
        {
          icon: data.icon2,
          iconAlt: "check mark",
          title: "Industry Veterans",
          p:
            "Since pioneering cloud security cameras, we’ve continued to create new, innovative ways to serve our customers. ",
        },
        {
          icon: data.icon3,
          iconAlt: "security",
          title: "Secure by Default",
          p:
            "Rhombus’ track record speaks for itself with zero-trust security framework and zero breaches.",
        },
        {
          icon: data.icon4,
          iconAlt: "support",
          title: "World-Class Support",
          p:
            "We guarantee you’ll receive a phenomenal experience from a team dedicated to your success.",
        },
      ],
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Request a Rhombus Free Trial - The #1 Choice for Cloud Physical
            Security
          </title>
          <meta
            name="description"
            content="See why school districts, cities, and Fortune 500 companies choose Rhombus to improve safety and operations."
          />
        </Helmet>
        <DemoPageTemplate data={pageData} formTitle="Free Trial" />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/demo/img/free-trial-header-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      mobileHeaderImage: file(
        relativePath: { eq: "components/demo/img/free-trial-header-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      learnImage: file(
        relativePath: { eq: "components/demo/img/r100-pole-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/lafs-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefit1: file(
        relativePath: { eq: "components/demo/img/infinite-scalability-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: { eq: "components/demo/img/seamless-deployment-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: { eq: "components/demo/img/easy-investigations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/trust.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/demo/img/secure-by-default.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(relativePath: { eq: "components/demo/img/headset.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
